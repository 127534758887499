<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" data-projection-id="90" style="opacity: 1">
        <div data-testid="hero-image-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <img style="width: 100%; height: 100%;" :src="require('@/assets/images/colabs/b1.png')" alt="" />
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-300 dark:text-iblack-300 max-w-[500px]">
                ZEN CAPITAL Impact Co-Labs
              </h1>
              <p class="text-lg text-iblack-300 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                Our Co-Labs are collaborative philanthropy funds that allow participating donors to make strategic,
                multi-year grants to a curated portfolio of high-impact giving opportunities. Each Co-Lab enables
                champion donors to learn from, collaborate with, and co-fund alongside a group of likeminded peers.
              </p>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div
              role="separator"
              aria-orientation="horizontal"
              class="w-full h-full block"
              data-projection-id="91"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <a
          draggable="false"
          target="_self"
          class="main-grid--in-grid transition-opacity duration-500 hover:opacity-80 focus:opacity-80"
          href="/impact"
          ><div
            class="relative transition-opacity duration-300 overflow-hidden"
            data-projection-id="92"
            style="   transform: translateY(40px) translateZ(0px)"
          >
            <div
              data-testid="tout-image-bg"
              class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
            >
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/colabs/b2.png')" alt="" />
            </div>
            <div
              class="md:h-[400px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[400px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
            >
              <div
                class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
              >
                <div
                  class="mt-[30px] md:my-0"
                  data-projection-id="93"
                  style="   transform: translateY(20px) translateZ(0px)"
                >
                  <h1 class="text-2xl text-iblack-700 md:text-iblack-700 dark:text-iblack-700 md:dark:text-iblack-700">
                    Climate Equity Co-Lab
                  </h1>
                  <p
                    class="text-lg text-iblack-700 dark:text-iblack-700 md:dark:text-iblack-700 md:text-iblack-700 mt-2.5 max-w-[500px]"
                  >
                    A $50 million philanthropic fund supporting organizations at the intersection of climate change,
                    gender equity, and poverty.
                  </p>
                </div>
              </div>
            </div>
          </div></a
        >
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <section class="" data-projection-id="94">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="95"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/colabs/b3.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <section class="" data-projection-id="96">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="97"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/colabs/b4.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <section class="" data-projection-id="98">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="99"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/colabs/b5.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <section class="" data-projection-id="100">
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="101"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Though climate change is the single most urgent issue humanity faces, we do not experience its
                          effects equally. The adverse effects of climate change are inequitably distributed, and those
                          who suffer most are often those who bear the least responsibility for the current climate
                          crisis. Women and girls are particularly vulnerable. Half a billion women and girls survive on
                          less than a $1.90 per day, and many—particularly those in the Global South—have livelihoods
                          that are directly tied to and dependent upon the land.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <section class="" data-projection-id="102">
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="103"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Women and girls often serve as families’ primary food producers, fuel collectors, and water
                          suppliers. Yet, despite their close relationship with the land, they are routinely denied
                          access to the resources and decision-making power needed to adapt to and cope with
                          environmental challenges. That is why ZEN CAPITAL Impact launched the Climate Equity Co-Lab—our
                          first-ever collaborative philanthropy fund supporting organizations at the intersection of
                          climate change, gender equity, and poverty.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div
          class="relative transition-opacity duration-300 overflow-hidden main-grid--in-grid"
          data-projection-id="104"
          style="   transform: translateY(40px) translateZ(0px)"
        >
          <div
            data-testid="tout-video-bg"
            class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
          >
            <div class="relative w-full h-full overflow-hidden max-h-[80vh] min-h-[100%] w-full">
              <div autoplay="" style="width: 100%; height: 100%">
                <img style="width: 100%; height: 100%;" :src="require('@/assets/images/colabs/b6.png')" alt="" />
              </div>
            </div>
          </div>
          <div
            class="md:h-[550px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[550px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
            >
              <div
                class="mt-[30px] md:my-0"
                data-projection-id="105"
                style="   transform: translateY(20px) translateZ(0px)"
              >
                <h1 class="text-2xl text-iblack-700 md:text-iblack-300 dark:text-iblack-300 md:dark:text-iblack-300">
                  Ocean Co-Lab
                </h1>
                <p
                  class="text-lg text-iblack-600 dark:text-iblack-600 md:dark:text-iblack-400 md:text-iblack-400 mt-2.5 max-w-[500px]"
                >
                  A $50 million philanthropic fund supporting solutions that<br class="hidden md:inline" />
                  will protect our ocean, and our planet, from a climate disaster.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <section class="" data-projection-id="106">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="107"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/colabs/b7.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <section class="" data-projection-id="108">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="109"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/colabs/b8.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <section class="" data-projection-id="110">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="111"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/colabs/b9.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <section class="" data-projection-id="112">
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="113"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Climate change is causing unprecedented damage to our ocean and the ecosystems it supports. As
                          the world’s largest carbon sink, the ocean is our most effective buffer against climate
                          change. Yet, despite its ability to deliver up to 21% of emission reductions needed by 2050,
                          funding for the ocean comprises just 7% of all climate giving.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <section class="" data-projection-id="114">
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="115"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Though ocean philanthropy has grown in recent years, only 2% of ocean funding is currently
                          directed toward solutions that help combat climate change. We launched the Ocean Co-Lab
                          because we know that by harnessing the power of our ocean, we can have an outsized impact in
                          the fight against climate change.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div
          class="relative transition-opacity duration-300 overflow-hidden main-grid--in-grid"
          data-projection-id="116"
          style="   transform: translateY(40px) translateZ(0px)"
        >
          <div
            data-testid="tout-image-bg"
            class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
          >
            <picture class="block transition-opacity duration-500 h-[550px] opacity-100"
              ><source
                srcset="
                  https://www.datocms-assets.com/65181/1656058343-tout-education.png?dpr=0.25  650w,
                  https://www.datocms-assets.com/65181/1656058343-tout-education.png?dpr=0.5  1300w,
                  https://www.datocms-assets.com/65181/1656058343-tout-education.png?dpr=0.75 1950w,
                  https://www.datocms-assets.com/65181/1656058343-tout-education.png          2601w
                " />
              <source />
              <img
                class="h-full w-full object-cover object-center object-cover object-bottom"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                alt=""
                draggable="false"
                style="object-position: 73% 63%"
            /></picture>
          </div>
          <div
            class="md:h-[550px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[550px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
            >
              <div
                class="mt-[30px] md:my-0"
                data-projection-id="117"
                style="   transform: translateY(20px) translateZ(0px)"
              >
                <h1 class="text-2xl text-iblack-700 md:text-iblack-300 dark:text-iblack-300 md:dark:text-iblack-300">
                  Education &amp; Economic Mobility Co-Lab
                </h1>
                <p
                  class="text-lg text-iblack-600 dark:text-iblack-600 md:dark:text-iblack-400 md:text-iblack-400 mt-2.5 max-w-[500px]"
                >
                  A $50 million philanthropic fund mobilizing capital to provide equitable education and career pathways
                  for all students in the<br class="hidden md:inline" />
                  United States.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <section class="" data-projection-id="118">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="119"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <picture class="block transition-opacity duration-500 h-full w-full opacity-100"
                        ><source
                          srcset="
                            https://www.datocms-assets.com/65181/1656093918-screenshot-2022-06-24-at-19-04-14.png?dpr=0.25  375w,
                            https://www.datocms-assets.com/65181/1656093918-screenshot-2022-06-24-at-19-04-14.png?dpr=0.5   750w,
                            https://www.datocms-assets.com/65181/1656093918-screenshot-2022-06-24-at-19-04-14.png?dpr=0.75 1125w,
                            https://www.datocms-assets.com/65181/1656093918-screenshot-2022-06-24-at-19-04-14.png          1500w
                          "
                          sizes="(min-width: 768px) 33vw, 100vw" />
                        <img
                          class="h-full w-full object-cover object-center"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                          alt=""
                          draggable="false"
                      /></picture>
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <section class="" data-projection-id="120">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="121"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <picture class="block transition-opacity duration-500 h-full w-full opacity-100"
                        ><source
                          srcset="
                            https://www.datocms-assets.com/65181/1655707147-mask-group-7.png?dpr=0.25 105w,
                            https://www.datocms-assets.com/65181/1655707147-mask-group-7.png?dpr=0.5  210w,
                            https://www.datocms-assets.com/65181/1655707147-mask-group-7.png?dpr=0.75 315w,
                            https://www.datocms-assets.com/65181/1655707147-mask-group-7.png          420w
                          "
                          media="(min-width: 768px)"
                          sizes="(min-width: 768px) 33vw, 100vw" />
                        <source
                          srcset="
                            https://www.datocms-assets.com/65181/1655707147-mask-group-7.png?dpr=0.25 105w,
                            https://www.datocms-assets.com/65181/1655707147-mask-group-7.png?dpr=0.5  210w,
                            https://www.datocms-assets.com/65181/1655707147-mask-group-7.png?dpr=0.75 315w,
                            https://www.datocms-assets.com/65181/1655707147-mask-group-7.png          420w
                          "
                          sizes="(min-width: 768px) 33vw, 100vw" />
                        <img
                          class="h-full w-full object-cover object-center"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                          alt=""
                          draggable="false"
                      /></picture>
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <section class="" data-projection-id="122">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      data-projection-id="123"
                      style="   transform: translateY(20px) translateZ(0px)"
                    >
                      <picture class="block transition-opacity duration-500 h-full w-full opacity-100"
                        ><source
                          srcset="
                            https://www.datocms-assets.com/65181/1655707166-mask-group-8.png?dpr=0.25 105w,
                            https://www.datocms-assets.com/65181/1655707166-mask-group-8.png?dpr=0.5  210w,
                            https://www.datocms-assets.com/65181/1655707166-mask-group-8.png?dpr=0.75 315w,
                            https://www.datocms-assets.com/65181/1655707166-mask-group-8.png          420w
                          "
                          media="(min-width: 768px)"
                          sizes="(min-width: 768px) 33vw, 100vw" />
                        <source
                          srcset="
                            https://www.datocms-assets.com/65181/1655707166-mask-group-8.png?dpr=0.25 105w,
                            https://www.datocms-assets.com/65181/1655707166-mask-group-8.png?dpr=0.5  210w,
                            https://www.datocms-assets.com/65181/1655707166-mask-group-8.png?dpr=0.75 315w,
                            https://www.datocms-assets.com/65181/1655707166-mask-group-8.png          420w
                          "
                          sizes="(min-width: 768px) 33vw, 100vw" />
                        <img
                          class="h-full w-full object-cover object-center"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                          alt=""
                          draggable="false"
                      /></picture>
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <section class="" data-projection-id="124">
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="125"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          In the 1940s, more than 90% of children grew up to earn more than their parents. Today, this
                          number has been cut in half. Education is a powerful pathway to success and can play a
                          critical role in propelling children upward. However, large opportunity gaps between children
                          from different backgrounds hamper educational outcomes and stunt intergenerational mobility
                          for the most marginalized groups.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <section class="" data-projection-id="126">
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <p
                          class="text-iblack-600 mt-2.5"
                          data-projection-id="127"
                          style="   transform: translateY(20px) translateZ(0px)"
                        >
                          Even today, a child’s racial and economic background remain the most powerful predictors of
                          educational attainment and future earning potential. The Education &amp; Economic Mobility
                          Co-Lab aims to help break down the systemic barriers that limit educational opportunities for
                          marginalized students across the country.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
